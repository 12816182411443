import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
    path: '',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },{
    path: 'listing',
    loadChildren: () => import('./features/listing/listing.module').then(m => m.ListingModule)
  },{
    path: 'review',
    loadChildren: () => import('./features/review/review.module').then(m => m.ReviewModule)
  },{
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
  },{
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },{
    path: 'register',
    loadChildren: () => import('./features/register/register.module').then(m => m.RegisterModule)
  },{
    path: 'error',
    loadChildren: () => import('./features/error/error.module').then(m => m.ErrorModule)
  },{
    path: 'faq',
    loadChildren: () => import('./features/faq/faq.module').then(m => m.FaqModule)
  },{
    path: 'contact',
    loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule)
  },{
    path: 'old',
    loadChildren: () => import('./features/old/old.module').then(m => m.OldModule)
  },{
    path: 'coming-soon',
    loadChildren: () => import('./features/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)
  },{
    path: '**',
    loadChildren: () => import('./features/error/error.module').then(m => m.ErrorModule)
  }];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
